import React from 'react';
import { Fade } from 'react-reveal';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';

function GoogleFormIntegration() {
  const googleFormUrl = "https://forms.gle/u6G7sCPdToDKJx9G8";

  const redirectToGoogleForm = () => {
    window.location.href = googleFormUrl;
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 py-12">
      <Fade duration={1000}>
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-red-500 mb-4">
          Register as AE Realtor
        </h1>
        <div className="flex flex-col md:flex-row justify-center items-center text-base sm:text-lg md:text-xl text-gray-700 mb-8">
          <div ref={ref} className="flex flex-col items-center mb-4 md:mb-0 md:mr-8">
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faUser} className="text-orange-500 text-2xl md:text-3xl lg:text-4xl mr-2" />
              {inView && (
                <CountUp start={0} end={1000} duration={3} delay={0.5} className="inline-block text-2xl md:text-3xl lg:text-4xl font-bold" />
              )}
            </div>
            <span className="text-lg">+ registrations</span>
          </div>
          <div ref={ref} className="flex flex-col items-center">
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faBuilding} className="text-orange-500 text-2xl md:text-3xl lg:text-4xl mr-2" />
              {inView && (
                <CountUp start={0} end={10} duration={3} delay={0.5} className="inline-block text-2xl md:text-3xl lg:text-4xl font-bold" />
              )}
            </div>
            <span className="text-lg">+ projects</span>
          </div>
        </div>
        <div className="text-center text-gray-700 mb-8">
          <p className="mb-2">Waiting for you</p>
          <p className="mb-2">Complete the form below to claim your AE NO</p>
        </div>
      </Fade>
      <Fade bottom duration={1000}>
        <a
          href="#_"
          className="relative text-base sm:text-lg md:text-xl lg:text-2xl group"
          onClick={redirectToGoogleForm}
        >
          <span className="relative z-10 block px-4 sm:px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span className="absolute inset-0 w-full h-full px-4 sm:px-5 py-3 rounded-lg bg-gray-50"></span>
            <span className="absolute left-0 w-36 sm:w-48 h-36 sm:h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span className="relative">Apply Now</span>
          </span>
          <span className="absolute bottom-0 right-0 w-full h-10 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"></span>
        </a>
      </Fade>
    </div>
  );
}

export default GoogleFormIntegration;
