// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth , GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCOAGQnZYFpAogSwxPxXts-GsPPk0_PCbY",
    authDomain: "declop-d310c.firebaseapp.com",
    projectId: "declop-d310c",
    storageBucket: "declop-d310c.appspot.com",
    messagingSenderId: "804188010435",
    appId: "1:804188010435:web:ef8504c5f0545eac4a6f76",
    measurementId: "G-4TRN0LE4HN"
  };

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app); 
export const googleProvider = new GoogleAuthProvider();


