
import React, { useState } from 'react';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import Header from '../components/Header'; // Import the Header component

function EnquiryPopup({ onClose }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-out">
      <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 sm:w-3/4 lg:w-1/2 animate-fadeIn">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900 text-2xl">&times;</button>
        </div>
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Have any projects? </h2>
        <div className="mb-4 text-lg text-gray-700 flex items-center">
          <FaPhoneAlt className="mr-2 text-orange-600" />
          <a href="https://wa.me/7989847054" target="_blank" rel="noopener noreferrer" className="underline hover:text-orange-600 transition duration-300 ease-in-out">Contact: 7989847054</a>
        </div>
        <div className="mb-4 text-lg text-gray-700">
          <p className="font-bold">For agent/associate related enquiries:</p>
          <p>
            <FaEnvelope className="inline mr-2 text-orange-600" />
            <a href="mailto:aeartheanrealtor@gmail.com" className="underline hover:text-orange-600 transition duration-300 ease-in-out">aeartheanrealtor@gmail.com</a>
          </p>
        </div>
        <div className="mb-4 text-lg text-gray-700">
          <p className="font-bold">For business proposals:</p>
          <p>
            <FaEnvelope className="inline mr-2 text-orange-600" />
            <a href="mailto:aearthean@gmail.com" className="underline hover:text-orange-600 transition duration-300 ease-in-out">aearthean@gmail.com</a>
          </p>
        </div>
        <div className="flex justify-end">
          <button onClick={onClose} className="bg-orange-600 text-white px-6 py-2 rounded-full hover:bg-orange-700 transition duration-300 ease-in-out">Close</button>
        </div>
      </div>
    </div>
  );
}
function ExplorePage() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const cities = [
    {
      name: 'Hyderabad',
      description: 'The city of pearls',
      imageSrc: 'hyderabad.jpg',
      isOperating: true,
    },
    {
      name: 'Mumbai',
      description: 'The city of dreams',
      imageSrc: 'mumbai.jpg',
      isOperating: false,
    },
    {
      name: 'Bengaluru',
      description: 'The silicon Valley of india',
      imageSrc: 'bengaluru.jpg',
      isOperating: false,
    },
    {
      name: 'Vizag',
      description: 'The city of destiny',
      imageSrc: 'vizag.jpg',
      isOperating: false,
    },
   
    {
      name: 'Chennai',
      description: 'The gateway of South india',
      imageSrc: 'chennai.jpg',
      isOperating: false,
    },
    {
      name: 'kolkata',
      description: 'The city of joy',
      imageSrc: 'kolkata.jpg',
      isOperating: false,
    },
    {
      name: 'Tirupati',
      description: 'The Lord city of venkateshwara',
      imageSrc: 'tirupati.jpg',
      isOperating: false,
    },
    {
      name: 'Nellore',
      description: 'The city of rocket launch',
      imageSrc: 'nellore.jpg',
      isOperating: false,
    },
    {
      name: 'Jammu and Kashmir',
      description: 'The city of temples',
      imageSrc: 'jk.jpg',
      isOperating: false,
    },
    {
      name: 'delhi',
      description: 'The capital city of india',
      imageSrc: 'delhi.jpg',
      isOperating: false,
    },
    {
      name: 'varanasi',
      description: 'the city of light',
      imageSrc: 'varanasi.jpg',
      isOperating: false,
    },
    {
      name: 'GIFT  city',
      description: 'The GIFT city of india',
      imageSrc: 'gift.jpg',
      isOperating: false,
    },
    {
      name: 'Ayodhya',
      description: 'The temple town of india',
      imageSrc: 'ayodya.jpg',
      isOperating: false,
    },
    {
      name: 'Goa',
      description: 'The pearl of orient',
      imageSrc: 'goa.jpg',
      isOperating: false,
    },
    
  ];

    return (
      <div className="min-h-screen bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        {/* Render the Header component */}
       
  
        {/* Back button */}
        <a href="/" className="absolute top-10 left-6 bg-gray-800 text-white px-4 py-2 rounded-full hover:bg-gray-900 text-lg">
          Back
        </a>
  
        <div className="max-w-5xl mx-auto text-center mt-16"> {/* Add margin-top to push the content below the header */}
          {cities.map((city, index) => (
            <div key={index} className="flex flex-col lg:flex-row items-center mb-8 bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="w-full lg:w-1/3 h-48 lg:h-auto flex-shrink-0">
                <img src={city.imageSrc} alt={city.name} className="w-full h-full object-cover object-center" />
              </div>
              <div className="w-full lg:w-2/3 p-6">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">{city.name}</h2>
                <p className="text-lg text-gray-700 mb-4">{city.description}</p>
                {city.isOperating ? (
                  <button
                    onClick={() => setIsPopupOpen(true)}
                    className="bg-orange-600 text-white px-4 py-2 rounded-full hover:bg-orange-700 transition duration-300 ease-in-out"
                  >
                    Click Here
                  </button>
                ) : (
                  <span className="text-gray-500">Coming Soon</span>
                )}
              </div>
            </div>
          ))}
        </div>
        {isPopupOpen && <EnquiryPopup onClose={() => setIsPopupOpen(false)} />}
      </div>
    );
  }

export default ExplorePage;

