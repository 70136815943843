import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, googleProvider } from "../config/firebase";
import { signInWithPopup } from "firebase/auth";

import Header from '../components/Header';

function SignIn() {
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, googleProvider);
    console.log(result);
    navigate("/Home");
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/* Site header */}
      <Header />

      {/* Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Welcome back. We exist to make entrepreneurism easier.</h1>
              </div>
              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form>
                  {/* Input fields for email and password */}
                </form>
                <div className="flex items-center my-6">
                  {/* Separator */}
                </div>
                <form>
                  <div className="w-full px-3">
                    <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full py-3 rounded-lg shadow-lg flex items-center" onClick={signInWithGoogle}>
                      <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        {/* Google icon */}
                      </svg>
                      <span className="flex-auto pl-16 pr-8 -ml-16">Continue with Google</span>
                    </button>
                  </div>
                </form>
                <div className="text-gray-600 text-center mt-6">
                  Don’t you have an account? <Link to="/signup" className="text-blue-600 hover:underline transition duration-150 ease-in-out">Sign up</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default SignIn;
