import React, { useState } from 'react';
import Cardc from './Cardc';
import EnquiryPopup from './EnquiryPopup';
import Fade from 'react-reveal/Fade';

function CardSet() {
  // State for managing popup visibility
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Handlers for opening and closing the popup
  const handleBookCall = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // Sample card data
  const cards = [
    {
      title: 'AE plots',
      description: 'Discover your perfect canvas for dreams and investments with our meticulously curated selection of open plots. From sprawling landscapes to prime urban locations, each parcel of land holds the promise of endless possibilities. Explore our collection and unlock the potential of your next venture or dream home.',
      isAvailable: true,
      imageSrc: 'plots.jpg',
    },
    {
      title: 'AE Flats',
      description: 'Discover comfortable apartment living with our selection of cozy flats. Explore spacious layouts, modern amenities, and convenient locations. Find your perfect home today!',
      isAvailable: true,
      imageSrc: 'flat.jpg',
    },
    {
      title: 'AE Villas',
      description: 'Explore our collection of beautiful villas, each a haven of comfort and style. Discover spacious living areas, serene bedrooms, and private outdoor spaces perfect for relaxation. Experience luxury living at its finest with our carefully curated selection of villas.',
      isAvailable: true,
      imageSrc: 'villa.jpg',
    },
    {
      title: 'AE Commercials',
      description: 'Discover prime commercial properties perfect for your business needs. Explore our curated selection of office spaces, retail shops, and industrial units in sought-after locations. Find the ideal space to elevate your business today.',
      isAvailable: true,
      imageSrc: 'commercial.jpg',
    },
  ];

  return (
    <div id="courses">
      <div className="p-4">
        <div className="text-center">
          <Fade bottom>
            <h2 className="text-2xl font-bold mb-4">Explore the segments</h2>
          </Fade>
        </div>
        <div className="flex flex-wrap justify-center gap-4 p-4">
          {cards.map((card, index) => (
            <Fade key={index} bottom>
              <Cardc
                title={card.title}
                description={card.description}
                imageSrc={card.imageSrc}
                isAvailable={card.isAvailable}
                onBookCall={handleBookCall}
              />
            </Fade>
          ))}
        </div>
      </div>
      {isPopupOpen && <EnquiryPopup onClose={handleClosePopup} />}
    </div>
  );
}

export default CardSet;
