import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppIcon = () => {
  const openWhatsApp = () => {
    // Replace 'YOUR_PHONE_NUMBER' with your WhatsApp phone number and 'YOUR_MESSAGE' with a predefined message.
    window.open(`https://wa.me/+917989847054?text=Hey,%20Can%20I%20have%20chat%20assistance%20please`);
  };

  return (
    <div className="fixed bottom-5 right-5 z-10">
      <button
        onClick={openWhatsApp}
        className="bg-green-500 p-3 rounded-full hover:bg-green-900"
        style={{
          borderRadius: '50%',
          height: '3rem', // Adjust the size as needed
          width: '3rem',  // Adjust the size as needed
        }}
      >
        <FontAwesomeIcon icon={faWhatsapp} className="text-white text-2xl" />
      </button>
    </div>
  );
};

export default WhatsAppIcon;
