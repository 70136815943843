import React from 'react';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

function EnquiryPopup({ onClose }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-out">
      <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 sm:w-3/4 lg:w-1/2 animate-fadeIn">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900 text-2xl">&times;</button>
        </div>
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Hey, how can we help you?</h2>
        <div className="mb-4 text-lg text-gray-700 flex items-center">
          <FaPhoneAlt className="mr-2 text-orange-600" />
          <a href="https://wa.me/7989847054" target="_blank" rel="noopener noreferrer" className="underline hover:text-orange-600 transition duration-300 ease-in-out">Contact: 7989847054</a>
        </div>
        <div className="mb-4 text-lg text-gray-700">
          <p className="font-bold">For agent/associate related enquiries:</p>
          <p>
            <FaEnvelope className="inline mr-2 text-orange-600" />
            <a href="mailto:aeartheanrealtor@gmail.com" className="underline hover:text-orange-600 transition duration-300 ease-in-out">aeartheanrealtor@gmail.com</a>
          </p>
        </div>
        <div className="mb-4 text-lg text-gray-700">
          <p className="font-bold">For investment proposals:</p>
          <p>
            <FaEnvelope className="inline mr-2 text-orange-600" />
            <a href="mailto:aearthean@gmail.com" className="underline hover:text-orange-600 transition duration-300 ease-in-out">aearthean@gmail.com</a>
          </p>
        </div>
        <div className="flex justify-end">
          <button onClick={onClose} className="bg-orange-600 text-white px-6 py-2 rounded-full hover:bg-orange-700 transition duration-300 ease-in-out">Close</button>
        </div>
      </div>
    </div>
  );
}
export default EnquiryPopup;