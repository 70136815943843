import React from 'react';
import Header from '../components/Header';
import HeroHome from '../components/HeroHome';
import FeaturesHome from '../components/Features';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import CardSet from '../components/CardSet';
import GoogleFormIntegration from '../components/GoogleFormIntegration';
import WhatsAppIcon from '../components/WhatsAppIcon'; // Import the WhatsApp icon component

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/* Site header */}
      <Header />

      {/* Page content */}
      <main className="flex-grow">
        {/* Page sections */}
        <HeroHome />
        <FeaturesHome />
        <CardSet />
        <GoogleFormIntegration />
        <Testimonials />
      </main>

      {/* WhatsApp Icon */}
      <WhatsAppIcon />

      {/* Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
