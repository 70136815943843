import React, { useState } from 'react';
import EnquiryPopup from './EnquiryPopup';

function HeroHome() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleEnquiryClick = (event) => {
    event.preventDefault();
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <section id="home" className="bg-cover bg-center bg-opacity-80 bg-fixed relative">
      <section className="relative mt-10 mb-10">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Hero content */}
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            {/* Section header */}
            <div className="text-center pb-12 md:pb-16">
              <h1 className="text-4xl md:text-6xl lg:text-7xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
                <span className="text-black-400">Hashtag#</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600" style={{ color: '#F94C10' }}> aearthean</span>
              </h1>
              <div className="max-w-3xl mx-auto">
                <p className="text-xl md:text-2xl lg:text-2xl text-gray-400 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                  Aearthean is a marketing solution company, where we bring aspiring agents/associates together marking them as @aeartheanrealtor
                </p>
                <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center space-evenly" data-aos="zoom-y-out" data-aos-delay="300">
                  <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                    <a href="#" onClick={handleEnquiryClick} className="relative inline-flex items-center px-8 md:px-12 py-3 text-lg md:text-xl font-medium  border-2 border-gray-300 rounded-full overflow-hidden group">
                      <span className="absolute left-0 w-full h-0 bg-orange-600 transition-all duration-300 ease-out group-hover:h-full top-1/2 group-hover:top-0"></span>
                      <span className="relative text-base text-center transition-colors duration-300 ease-out group-hover:text-gray-900">Enquiry</span>
                    </a>
                    <div className="ml-2 mb-6 md:mb-0">
                      <a href="/explore" className="inline-flex items-center justify-center w-full px-6 md:px-8 py-3 text-base md:text-lg font-bold leading-6 text-white bg-orange-600 border border-transparent rounded-full md:w-auto hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 transition duration-300 ease-in-out">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isPopupOpen && <EnquiryPopup onClose={handleClosePopup} />}
      </section>
    </section>
  );
}

export default HeroHome;
