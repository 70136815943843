import React, { useState, useEffect } from 'react';

function Header() {
  const [top, setTop] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  // Detect whether the user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  // Function to toggle the menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${top ? '' : 'bg-white shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex-shrink-0 mr-4">
            <a href="#home">
              <img
                src="logo.jpg" 
                alt="logo"
                className="w-10 h-15"
              />
            </a>
          </div>
          <nav className={`md:flex flex-grow ${menuOpen ? 'block' : 'hidden'}`}>
            <ul className="flex justify-end items-center space-x-4">
              <li>
                <a href="#home" className="font-medium  hover:text-orange-300 transition duration-300 ease-in-out text-sm">
                  Home
                </a>
              </li>
              <li>
                <a href="#about" className="font-medium  hover:text-orange-300 transition duration-300 ease-in-out text-sm">
                  About
                </a>
              </li>
             
            </ul>
          </nav>
          <button className="md:hidden" onClick={toggleMenu}>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6h16M4 12h16M4 18h16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
